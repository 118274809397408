const convertDate2StringDate = (dateData, seperateSign) => {
  var yyyy = dateData.toLocaleDateString().slice(0, 4);
  var MM =
    (dateData.getMonth() + 1 < 10 ? '0' : '') + (dateData.getMonth() + 1);
  var dd = (dateData.getDate() < 10 ? '0' : '') + dateData.getDate();
  // var h = (dateData.getHours()<10 ? '0' : '')+dateData.getHours();
  // var m = (dateData.getMinutes()<10 ? '0' : '')+dateData.getMinutes();
  // var s = (dateData.getSeconds()<10 ? '0' : '')+dateData.getSeconds();

  return yyyy + seperateSign + MM + seperateSign + dd;
};

const convertDate2StringTime = (dateData, seperateSign) => {
  var h = (dateData.getHours() < 10 ? '0' : '') + dateData.getHours();
  var m = (dateData.getMinutes() < 10 ? '0' : '') + dateData.getMinutes();
  var s = (dateData.getSeconds() < 10 ? '0' : '') + dateData.getSeconds();

  return `${h}${seperateSign}${m}${seperateSign}${s}`;
};

const convertDate2StringDateTime = (dateData, seperateSign) => {
  var yyyy = dateData.toLocaleDateString().slice(0, 4);
  var MM =
    (dateData.getMonth() + 1 < 10 ? '0' : '') + (dateData.getMonth() + 1);
  var dd = (dateData.getDate() < 10 ? '0' : '') + dateData.getDate();
  var h = (dateData.getHours() < 10 ? '0' : '') + dateData.getHours();
  var m = (dateData.getMinutes() < 10 ? '0' : '') + dateData.getMinutes();
  var s = (dateData.getSeconds() < 10 ? '0' : '') + dateData.getSeconds();

  return `${yyyy}${seperateSign}${MM}${seperateSign}${dd} ${h}:${m}:${s}`;
};

const getFormatDate = (dateString) => {
  return typeof dateString === 'string'
    ? dateString.split(' ')[0].replace(/(\d{4})(\d{2})(\d{2})/, '$1/$2/$3')
    : undefined;
};

export {
  convertDate2StringDate,
  convertDate2StringTime,
  convertDate2StringDateTime,
  getFormatDate,
};
