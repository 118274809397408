import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import styled, { css } from 'styled-components';

import Skeleton from 'react-loading-skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isDesktop, isMobile } from 'react-device-detect';

import Auth from 'components/Auth';
import Layout from 'components/Order/Layout';
import Modal from 'components/UI/Modal';
import { RadioGroup } from 'components/UI/Radio';
import Pagination from 'components/UI/Pagination';
import CcAuth from 'components/CcAuth';
import TitleName from 'components/UI/Header/TitleName';

import { md } from 'utils/breakpoints';
import { useAPI } from 'utils/api';
import { convertDate2StringDate } from 'utils/_dateHandler';

import IconFilter from 'images/filter.svg';

const mixinRefundList = css`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;

  @media (max-width: ${md}) {
    gap: 24px 0;
  }
`;

const mixinRefundItem = css`
  flex-basis: calc((100% - 12px) / 2);
  border-radius: 8px;
  min-height: 127px;

  @media (max-width: ${md}) {
    flex-basis: 100%;
  }
`;
const Container = styled.div`
  .skeleton-refund-list {
    ${mixinRefundList};

    .skeleton-refund {
      ${mixinRefundItem};
    }
  }
`;

const StyledModal = styled(Modal)`
  .content-wrapper {
    width: 332px;

    @media (max-width: ${md}) {
      width: 300px;
    }
  }
`;

const TitleBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  @media (max-width: ${md}) {
    display: none;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;
`;

const BtnGroup = styled.div`
  display: flex;
  gap: 0 8px;

  @media (max-width: ${md}) {
    display: none;
  }
`;

const TitleBarBtn = styled.div`
  width: 114px;
  height: 38px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 30px;

  ${(props) =>
    props.isActive &&
    css`
      background-color: #eafeff;
      border: 1px solid #5fd2da;
      border-radius: 30px;
    `}
`;

const RefundListWrapper = styled.div`
  ${mixinRefundList}
  @media (max-width: ${md}) {
    width: 100%;
    background-color: #fff;
    justify-content: center;
    padding-top: 16px;
    gap: 16px 0;
  }
`;

const RefundItem = styled.div`
  ${mixinRefundItem}
`;

const RefundItemTitle = styled.div`
  background-color: rgba(95, 210, 218, 0.25);
  border: 1px solid #5fd2da;
  padding: 4px 16px;
  font-size: 16px;
  font-weight: 400;
  color: #3b3516;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${md}) {
    font-size: 14px;
  }
`;

const RefundItemContent = styled.div`
  background-color: #ffffff;
  border-radius: 0px 0px 8px 8px;
  padding: 8px 14px 16px;
  display: flex;
  flex-direction: column;
  gap: 4px 0;

  @media (max-width: ${md}) {
    padding: 8px 14px 12px;
    border-bottom: 1px solid #e4e4e4;
  }
`;

const RefundItemContentRow = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #999999;

  @media (max-width: ${md}) {
    font-size: 14px;
  }

  &:nth-child(1) {
    display: flex;
    justify-content: space-between;

    > span {
      &:first-child {
        font-size: 18px;
        font-weight: 700;
        color: #3b3516;

        @media (max-width: ${md}) {
          font-size: 16px;
        }
      }

      &:last-child {
        font-size: 18px;
        font-weight: 400;
        color: #3b3516;

        @media (max-width: ${md}) {
          font-size: 16px;
        }
      }
    }
  }
`;

const ModalWrapper = styled.div`
  padding: 16px 32px;
`;

const ModalTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  text-align: center;
  margin-bottom: 16px;
`;

const StyledRadioGroup = styled(RadioGroup)`
  margin-bottom: 19px;
  gap: 15px 0;
`;

const ModalBtn = styled.div`
  width: 100%;
  height: 52px;
  background-color: #5fd2da;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  cursor: pointer;
`;

const FilterBtn = styled.div`
  width: 64px;
  height: 46px;
  background-color: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 30px;
  display: none;
  align-items: center;
  justify-content: center;

  @media (max-width: ${md}) {
    display: flex;
    order: 3;
    margin-left: auto;
  }
`;
const StyledPagination = styled(Pagination)`
  margin-top: 20px;
`;

const filterList = [
  { label: '最近三個月訂單', value: '0' },
  { label: '三個月以上', value: '1' },
];

let total = 0;
let startDate = '2020-01-01';
let endDate = '2020-12-31';
let currentPage = 1;
let totalPage = 0;
const perPage = 10;
let arrRefund = [];

const RefundList = (props) => {
  const { location } = props;
  const api = useAPI();
  const pageTitle = '退訂退款訂單';
  const [modalFilter, setModalFilter] = useState(false);
  const [refoundList, setRefoundList] = useState();
  const [filterValue, setFilterValue] = useState('0');
  const [hasMore, setHasMore] = useState(false);
  const [ccAuthIsReady, setCcAuthIsReady] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  if (isDesktop)
    currentPage = searchParams.get('page')
      ? Number(searchParams.get('page'))
      : 1;
  const openFilterModal = () => {
    setModalFilter(true);
  };

  const closeFilterModal = () => {
    setModalFilter(false);
  };
  const switchFilter = () => {
    if (filterValue === '0') {
      const start = new Date();
      const end = new Date();
      start.setMonth(end.getMonth() - 3);

      startDate = convertDate2StringDate(start, '-');
      endDate = convertDate2StringDate(end, '-');
    } else {
      const start = new Date();
      const end = new Date();
      start.setMonth(end.getMonth() - 12);
      end.setMonth(end.getMonth() - 3);

      startDate = convertDate2StringDate(start, '-');
      endDate = convertDate2StringDate(end, '-');
    }
    currentPage = 1;
    totalPage = 0;
    setRefoundList();
    getRefoundList();
  };
  const getRefoundList = () => {
    api
      .getRefoundList(startDate, endDate, currentPage, perPage)
      .then((res) => {
        const rowArr = res.rows;
        if (isMobile) {
          if (currentPage === 1 || currentPage <= totalPage) {
            setHasMore(true);
            arrRefund = [...arrRefund, ...rowArr];
            currentPage = currentPage + 1;
            totalPage = Number.parseInt(res.total / perPage);
            if (res.total % perPage !== 0) {
              totalPage++;
            }
            if (res.total === res.rows.length) {
              setHasMore(false);
            }
            setRefoundList(arrRefund);
          } else {
            setHasMore(false);
          }
        } else {
          total = res.total;
          setRefoundList(rowArr);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const goToRefundInfo = (code) => {
    navigate(`/refund/${code}`);
  };

  const changPage = (page) => {
    navigate(`/refund?page=${page}`);
    currentPage = page;
    setRefoundList();
    getRefoundList();
  };

  useEffect(() => {
    if (!ccAuthIsReady) return;
    if (isMobile) {
      currentPage = 1;
      totalPage = 0;
      arrRefund = [];
    }

    const start = new Date();
    const end = new Date();
    start.setMonth(end.getMonth() - 3);
    startDate = convertDate2StringDate(start, '-');
    endDate = convertDate2StringDate(end, '-');
    getRefoundList();
  }, [ccAuthIsReady]);

  return (
    <>
      <TitleName />
      <CcAuth onFinished={() => setCcAuthIsReady(true)} />
      {ccAuthIsReady && (
        <div>
          <Layout
            {...props}
            pageTitle={pageTitle}
            extraBtn={
              <FilterBtn className="filter-btn" onClick={openFilterModal}>
                <img src={IconFilter} />
              </FilterBtn>
            }
          >
            <Auth />
            <Container>
              <TitleBar>
                <Title>{pageTitle}</Title>
                <BtnGroup>
                  <TitleBarBtn onClick={openFilterModal}>篩選訂單</TitleBarBtn>
                </BtnGroup>
              </TitleBar>
              {refoundList !== undefined ? (
                <RefundListWrapper>
                  {isMobile ? (
                    <InfiniteScroll
                      ref={refoundList}
                      dataLength={refoundList?.length ?? 0}
                      next={getRefoundList}
                      hasMore={hasMore}
                      style={{ height: '100%', overflow: 'unset' }}
                      loader={<h6>資料載入中...請稍候</h6>}
                    >
                      {refoundList?.map((item) => {
                        return (
                          <RefundItem
                            onClick={() => goToRefundInfo(item.shiprefund_code)}
                          >
                            <RefundItemTitle>
                              <span>{item.shiprefund_code}</span>
                              <span>{item.refund_status}</span>
                            </RefundItemTitle>
                            <RefundItemContent>
                              <RefundItemContentRow>
                                <span>{item.order_no}</span>
                                <span>{item.ecorder_payment}</span>
                              </RefundItemContentRow>
                              <RefundItemContentRow>
                                品項{item.order_item_qty}・${item.refund_amount}
                              </RefundItemContentRow>
                              <RefundItemContentRow>
                                {`${item.apply_refund_date} ${item.apply_refund_time}`}
                              </RefundItemContentRow>
                            </RefundItemContent>
                          </RefundItem>
                        );
                      })}
                    </InfiniteScroll>
                  ) : (
                    <>
                      {refoundList?.map((item) => {
                        return (
                          <RefundItem
                            onClick={() => goToRefundInfo(item.shiprefund_code)}
                          >
                            <RefundItemTitle>
                              <span>{item.shiprefund_code}</span>
                              <span>{item.refund_status}</span>
                            </RefundItemTitle>
                            <RefundItemContent>
                              <RefundItemContentRow>
                                <span>{item.order_no}</span>
                                <span>{item.ecorder_payment}</span>
                              </RefundItemContentRow>
                              <RefundItemContentRow>
                                品項{item.order_item_qty}・${item.refund_amount}
                              </RefundItemContentRow>
                              <RefundItemContentRow>
                                {`${item.apply_refund_date} ${item.apply_refund_time}`}
                              </RefundItemContentRow>
                            </RefundItemContent>
                          </RefundItem>
                        );
                      })}
                    </>
                  )}
                </RefundListWrapper>
              ) : (
                <Skeleton
                  containerClassName="skeleton-refund-list"
                  className="skeleton-refund"
                  count={10}
                  inline
                />
              )}
              {refoundList !== undefined &&
                refoundList?.length > 0 &&
                isDesktop && (
                  <StyledPagination
                    total={total}
                    current={currentPage}
                    pageSize={perPage}
                    onPageChange={(page) => changPage(page)}
                  />
                )}
            </Container>
          </Layout>
          <StyledModal open={modalFilter} onClose={closeFilterModal}>
            <ModalWrapper>
              <ModalTitle>篩選</ModalTitle>
              <StyledRadioGroup
                name="refund-filter"
                list={filterList}
                value={filterValue}
                defaultValue={filterValue}
                align="vertical"
                onChange={(e) => {
                  setFilterValue(e.target.value);
                }}
              />
              <ModalBtn
                onClick={() => {
                  switchFilter();
                  setModalFilter(false);
                }}
              >
                確定
              </ModalBtn>
            </ModalWrapper>
          </StyledModal>
        </div>
      )}
    </>
  );
};

export default RefundList;
